import React from "react";
// import { Link } from "gatsby"
import "./style.scss";



// write as a react hook
function ImageText(props) {
  return (
    <div className="ImageText">
      <div>
        <img className={`${props.left ? "imageRight": ""}`} src={props.image}/>
        <div>
          <h1>{props.mainHeading}
          </h1>
        <h2>{props.secHeading}
            </h2></div>
      </div>
    </div>
  )
}



function ImageTextList(props){

return(
    <div className="ImageTextList">

    {props.data.filter((item) => {
        
        return true
       }).map((item) => (
                <ImageText
                    mainHeading={item.message}
                    secHeading={item.subHeading}
                    image={item.imageOrGraphic.url}>
                </ImageText>
           ))}  


</div>
)



}

export default ImageTextList