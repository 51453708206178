import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ServiceCards from './imageText'


const ImageTextQuery = () => (
  <StaticQuery
    query={graphql`
    {
      datoCmsHomePage {
        content {
          message
          subHeading
          imageOrGraphic {
            url
          }
        }
      }
    }
    `}
    render={data => <ServiceCards data={data.datoCmsHomePage.content} />}
  ></StaticQuery>
)

export default ImageTextQuery
